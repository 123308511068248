<template>
  <div class="container">
    <img class="profile-img" :src="getProfile.image" alt="profile image" />
    <h4 class="text-blue">Name: {{ getProfile.name }}</h4>
    <p class="text-blue">Email: {{ getUser.email }}</p>
    <p class="text-blue">Profession: {{ getProfile.position }}</p>
    <p class="text-blue">Roles: {{ getProfile.roles.join(", ") }}</p>
    <button class="btn btn-success">
      <router-link to="/profile/edit">Edit Profile</router-link>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MyProfile",
  computed: { ...mapGetters(["getProfile", "getUser"]) },
};
</script>


<style scoped>
.profile-img {
  height: 250px;
  width: 250px;
  border: none;
  border-radius: 50%;
}

a {
  text-decoration: none;
  color: white;
}
</style>
